import React from "react";


const YouTubeEmbed = ({ videoId }) => {

    return (
        <div className="d-flex justify-content-center align-items-center pt-2 pb-4">
            <iframe
                width="100%"
                height="315"
                style={{ maxWidth: "560px" }}
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg shadow-lg"
            ></iframe>
        </div>
  );
};

export default YouTubeEmbed;
